<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mb_8 flexHV">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()"> 刷新</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="录入开始日期"
          end-placeholder="录入结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getRequs()"> 查询</el-button>
        <el-button type="warning" size="small" class="vd_export" icon="el-icon-document-copy" @click="copyAndEdit()">复制并编辑</el-button>
        <div style="margin-left: auto">
          <div style="display: flex">
            <div style="width: 250px">款数合计:{{ sumRequNumMode }}</div>
            <el-button type="danger" size="small" plain @click="goToRequBackDetail()"> 退单明细</el-button>
          </div>
        </div>
      </div>
      <el-radio-group class="vg_mb_8" size="small" v-model="type" @input="val => getDataByType(val)">
        <el-radio-button :label="1">毛绒</el-radio-button>
        <el-radio-button :label="2">辅材</el-radio-button>
        <el-radio-button :label="3">非毛绒</el-radio-button>
      </el-radio-group>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`requ_id`"
        :rowStyle="setRowStyle"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        :need-page-sizes="true"
        :page-size="50"
        :showSummary="true"
        :summariesColumns="['requ_num_mode']"
        :stripe="false"
        @select="selectRows"
        @row-dblclick="dbClickJp"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @getTableData="getRequs"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
        <template v-slot:generate_status="scope">
          <div style="text-align: center">
            <el-tag v-if="scope.row.generate_status === 0" type="info">未生成</el-tag>
            <el-tag v-if="scope.row.generate_status === 1" type="warning">部分生成</el-tag>
            <el-tag v-if="scope.row.generate_status === 2" type="success">已生成</el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { requAPI } from '@api/modules/requ';
import { stffAPI } from '@api/modules/staff';
import { custAPI } from '@api/modules/cust';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getDateNoTime } from '@assets/js/dateUtils';
import UrlEncode from '@assets/js/UrlEncode';
import { getStff } from '@api/public';

export default {
  name: 'RequList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        {
          prop: 'generate_status',
          itemType: 'select',
          options: [
            { value: 0, label: '未生成' },
            { value: 1, label: '部分生成' },
            { value: 2, label: '已生成' }
          ],
          label: '工厂打样单情况',
          sortable: false,
          labelWidth: '110px',
          input: true
        },
        { prop: 'requ_no', itemType: 'input', label: '委托打样单号', sortable: false, labelWidth: '110px' },
        {
          prop: 'sub_time',
          itemType: 'date',
          label: '提交时间',
          input: true,
          sortable: false,
          labelWidth: '110px',
          valueFormat: 'timestamp',
          formatter: val => getDateNoTime(val, false)
        },
        {
          prop: 'requ_content',
          itemType: 'input',
          label: '样品要求',
          input: true,
          labelWidth: '100px',
          sortable: false,
          overflowTooltip: true
        },
        { prop: 'requ_num', itemType: 'input', label: '打样数量', labelWidth: '100px' },
        { prop: 'requ_num_mode', itemType: 'input', label: '款数', labelWidth: '60px', sortable: false, input: false },
        { prop: 'create_time', itemType: 'date', label: '录入时间', formatter: val => getDateNoTime(val, true), labelWidth: '100px' },
        { prop: 'requ_pedate', itemType: 'date', label: '要求交样日期', formatter: val => getDateNoTime(val, true), labelWidth: '120px', sortable: true },
        { prop: 'requ_stff_name', itemType: 'select', options: [], label: '委托人', sortable: false, labelWidth: '100px' },
        {
          prop: 'requSmplQuot',
          itemType: 'input',
          label: '委托类型',
          input: false,
          sortable: false,
          labelWidth: '100px'
        },
        { prop: 'requ_taker_name', itemType: 'input', label: '打样接收人', sortable: false, labelWidth: '100px' },
        { prop: 'requ_pricer_name', itemType: 'input', label: '核价接收人', sortable: false, labelWidth: '100px' },
        { prop: 'requ_quoter_name', itemType: 'input', label: '报价接收人', sortable: false, labelWidth: '100px' },
        { prop: 'cust_abbr', itemType: 'input', label: '客户简称', sortable: false, overflowTooltip: true },
        { prop: 'desi_no_strs', itemType: 'input', label: '设计单号', sortable: false, overflowTooltip: true, labelWidth: '100px' },
        { prop: 'requ_dept_name', itemType: 'input', label: '提交审核部门', sortable: false, labelWidth: '100px' },
        { prop: 'dequ_no_strs', itemType: 'input', label: '委托设计单号', sortable: false, overflowTooltip: true, labelWidth: '100px' },
        { prop: 'stff_name', itemType: 'input', label: '录入人', labelWidth: '100px' },
        this.$store.state.status
      ],
      searchForm: {
        requ_no: null,
        desi_no: null,
        dequ_no: null,
        requ_dept_id: null,
        requ_taker: null,
        status: null,
        requ_stff_id: null,
        cust_id: null,
        stff_id: null,
        timeValue: {
          startTime: null,
          endTime: null
        }, //录入时间
        timeValue1: {
          startTime: null,
          endTime: null
        } //要求交样日
      },
      sumRequNumMode: null,
      loadFlag: true,
      requStffList: [],
      custList: [],
      requDeptList: [],
      requTakerList: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      type: 1,
      multiSelection: [],
      btn: {},
      requDeptForm: {},
      stffUserList: [],
      statusList: [
        { id: 0, label: '草拟' },
        { id: 1, label: '在批' },
        { id: 2, label: '生效' }
      ]
    };
  },
  async mounted() {
    this.tableProperties.find(x => x.prop === 'requ_stff_name').options = await getStff('', 'default', '', 'stff_name');
    this.$refs.multiTable.searchForm.requ_type = 1;
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/requ_add' || from.path === '/requ_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getRequs();
      this.getRequStffList();
      this.getCustList();
      this.getProdTypeList();
      this.getStffUser();
    },
    //获取list
    getRequs() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime?.getTime();
      searchForm.end_time = endTime?.getTime();
      getNoCatch(requAPI.getRequs, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.sumRequNumMode = data.sumRequNumMode;
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    // 获取提交审核部门
    getProdTypeList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10007 })
        .then(res => {
          if (res.data.code === 0) {
            this.requDeptList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 获取委托人
    getRequStffList() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.requStffList = res.data.data;
          }
        })
        .catch(() => {});
    },
    // 获取委打样接收人
    getRequStffListCopy() {
      get(stffAPI.getAllStffsV1, { dept_id: this.requDeptForm.param2 })
        .then(res => {
          if (res.data.code === 0) {
            this.requTakerList = res.data.data;
          }
        })
        .catch(() => {});
    },
    // 获取客户
    getCustList() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
          }
        })
        .catch(() => {});
    },
    // 刷新
    buttonRefresh() {
      this.type = 1;
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getDataByType(1);
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getRequs();
    },
    // 多选获取勾选内容
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/requ_add?perm_id=${permId}`);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.requ_id);
      });
      post(requAPI.deleteRequByIds, { requ_id_list: ids })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/requ_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.requ_id
          })
        )
      });
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getDataByType(type) {
      this.$refs.multiTable.searchForm.requ_type = type;
      this.getRequs();
    },
    copyAndEdit() {
      if (this.multiSelection.length !== 1) return this.$message.warning('请选择一条数据！');
      requAPI.copyAndEdit({ requ_id: this.multiSelection[0].requ_id }).then(({ data }) => {
        this.dbClickJp({ requ_id: data.form_id });
      });
    },
    goToRequBackDetail() {
      this.jump('/requ_back_detail');
    },
    setRowStyle({ row }) {
      const styleMap = {
        0: { height: '80px' },
        1: { background: '#fdf6ec', height: '80px' },
        2: { background: '#f0f9eb', height: '80px' }
      };
      return styleMap[row.generate_status] || {};
    }
  }
};
</script>

<style scoped lang="scss"></style>
